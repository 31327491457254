import React from "react"
import SectionColumns from "../components/section-columns/section-columns";
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby";
import Slider from "../components/slider/slider";
import Header from "./../components/header/header";
import Button from "./../components/button/button";

export const query = graphql`
  query {
    slider: file(relativePath: { eq: "jtw-3d.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 499, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    allYaml {
      nodes {
        index {
          seo{
            title,
            description,
            url,
            lang
          },
          banner{
            title{
              line1,
              line2,
              line3
            },
            description,
            button{
              text
            }
          },
          columns{
            title{
              line1,
              line2,
              line3
            },
            description,
            items{
              ux{
                title{
                  line1,
                  line2
                },
                description
              },
              webdesign{
                title{
                  line1,
                  line2
                },
                description
              },
              app{
                title{
                  line1,
                  line2
                },
                description
              }
            }
          }
        }
      }
    }
  }
`
const OfferPage = ({data}) => {
  const sectionColumnsTitle = <span>{data.allYaml.nodes[0].index.columns.title.line1} {data.allYaml.nodes[0].index.columns.title.line2}<br/> {data.allYaml.nodes[0].index.columns.title.line3}</span>;
  const sectionColumnsDescription = data.allYaml.nodes[0].index.columns.description;
  const sectionColumnsColumns = [
    {
      title: <span>Strony<br/> internetowe</span>,
      description: <span>Wykorzystując nowe technologie Gatsby/React i hosting Netlify, tworzymy szybkie i nowoczesne strony, które ułatwiają dotarcie do dużej ilości odbiorców w bardzo krótkim czasie</span>,
    },
    {
      title: <span>{data.allYaml.nodes[0].index.columns.items.ux.title.line1}<br/>{data.allYaml.nodes[0].index.columns.items.ux.title.line2}</span>,
      description: data.allYaml.nodes[0].index.columns.items.ux.description,
    },
    {
      title: <span>{data.allYaml.nodes[0].index.columns.items.webdesign.title.line1}<br/>{data.allYaml.nodes[0].index.columns.items.webdesign.title.line2}</span>,
      description: data.allYaml.nodes[0].index.columns.items.webdesign.description,
    },
    {
      title: <span>{data.allYaml.nodes[0].index.columns.items.app.title.line1}<br/> {data.allYaml.nodes[0].index.columns.items.app.title.line2}</span>,
      description: data.allYaml.nodes[0].index.columns.items.app.description,
    }
  ];

  return <Layout>
    <SEO 
      title={data.allYaml.nodes[0].index.seo.title}
      description={data.allYaml.nodes[0].index.seo.description}
      url={data.allYaml.nodes[0].index.seo.url}
      lang={data.allYaml.nodes[0].index.seo.lang}
    />
    <Header>
      <Slider 
        image={data.slider.childImageSharp.fluid} 
        textLeft="Zrealizujemy"
        textRight="każdy Twój projekt" />
    </Header>
    <main id="main">
      <SectionColumns 
        title={sectionColumnsTitle} 
        description={sectionColumnsDescription} 
        columns={sectionColumnsColumns}
        afterContent={<Button text="Napisz do nas" url="#footer"/>}
      />
    </main>
  </Layout>
}

export default OfferPage